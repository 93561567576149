import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import Logo from "../../images/miner_wizard.png";

import ConnectWalletBtn from '../../components/ConnectWalletBtn';


const useStyles = makeStyles((theme) => {
    return {
      container: {
        backgroundColor: "#2a314a",
        padding: theme.spacing(4, 8, 1),
        color: "#11d0ed"
      },
      logo: {
        borderRadius: "50%",
        height: "auto",
        width: "70%",
        padding: "0"
      },
      buttonMargin: {
        marginTop: theme.spacing(8)
      },
      btnColor: {
        backgroundColor: "#36478a",
        color: "#11d0ed",
        "&:hover": {
          backgroundColor: "#2a314a",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "#2a314a",
          }
        }
      },
    }
});


function Welcome(props) {
    const {setIsConnected} = props;
    const classes = useStyles();
    
    return (
        <div className={ classes.container }>
            <Box ml={3} mt={2} mb={4} display="flex" justifyContent="flex-end">
              <ConnectWalletBtn classes={classes} setIsConnected={setIsConnected}/>
            </Box>
            <Grid container direction="row" justifyContent="start" alignItems="center">
              <Grid container item justifyContent="center" lg={3} md={3} sm={3}>
                <Box 
                      className={ classes.logo }
                      component="img"
                      alt="MIM Machine Logo"
                      src={Logo}
                  />
              </Grid>
              <Grid container item lg={6} md={6} sm={6} justifyContent="center">
                <Typography component="h1" variant="h2" align="center" gutterBottom>
                    <strong>MIM STAR MINER ⭐</strong> 
                </Typography>
              </Grid>
              <Grid container item justifyContent="center" lg={3} md={3} sm={3}>
                <Box 
                      className={ classes.logo }
                      component="img"
                      alt="MIM Machine Logo"
                      src={Logo}
                  />
              </Grid>
            </Grid>
        </div>    
    );
  }
  
export default Welcome;