import React from "react";
import Main from "./pages/Main.jsx";
import Miner from "./pages/Miner.jsx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { DAppProvider, ChainId, Localhost } from "@usedapp/core"

function App() {
    return (
      <DAppProvider config={{multicallAddresses: {
        [ChainId.Localhost]: '0x6951b5Bd815043E3F842c1b026b0Fa888Cc2DD85',
        [43113]: '0xccc75e78Dce6A20bCCa3a30deB23Cb4D23df993a'
      },
      }}>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/miner" element={<Miner />} />          
        </Routes>
      </Router>
      </DAppProvider>
    );
  }
  
export default App;