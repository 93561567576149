import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';


const textColorContrastText = "#3494E6"
const useStyles = makeStyles((theme) => {
    return {
      container: {
          backgroundColor: "#36478a",
          padding: theme.spacing(4, 8, 6),
          color: "#11d0ed"
      }
    }
});


function LaunchingSoon() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="flex-start">
                <Typography variant='h2'><strong>STABLECOIN MINER LAUNCHING MAY 13 ⭐</strong> </Typography>
            </Grid>
        </div>    
    );
  }
  
export default LaunchingSoon;