import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import { useEthers } from '@usedapp/core';


import Logo from "../../images/miner_wizard.png";

import ConnectWalletBtn from '../../components/ConnectWalletBtn';


const useStyles = makeStyles((theme) => {
    return {
      container: {
        backgroundColor: "#2a314a",
        padding: theme.spacing(4, 8, 6),
        paddingTop: "0",
        color: "#FBF5B7"
      },
      buttonMargin: {
        marginTop: theme.spacing(8)
      },
      btnColor: {
        backgroundColor: "#36478a",
        color: "#11d0ed",
        "&:hover": {
          backgroundColor: "#2a314a",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "#2a314a",
          }
        }
      },
      card: {
        backgroundColor: "#2a314a",
        padding: theme.spacing(4, 6, 4),
        paddingTop: "0",
        color: "#11d0ed",
        borderColor: "#FBF5B7",
        width: "30rem"
      },
      notchedOutline: {
        borderColor: "#11d0ed !important",
      },
      textFieldColor: {
        color: "#11d0ed !important",
        fontWeight: "bold",
        fontSize:"1.4rem",
        textAlign:"right"
      },
      textFieldColorRef: {
        color: "#11d0ed !important",
        fontWeight: "bold",
        fontSize:"1rem",
      },
      btnColorCard: {
        backgroundColor: "#36478a",
        color: "#11d0ed",
        "&:hover": {
          backgroundColor: "#FBF5B7",
          color:"#000000",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "#FBF5B7",
            color:"#000000",
          }
        }
      },
      divider: {
        marginTop: "8%",
        width: "100%",
        backgroundColor: "#ffffff"
      },
      dividerLessMargin: {
        marginTop: "2%",
        width: "100%",
        backgroundColor: "#ffffff"
      },
      gridMargin: {
          marginTop: "4%"
      },
      bronzeColor: {
        color: "#E9C38A"
      },
      silverColor: {
        color: "#E1E0E4"
      },
      goldColor: {
          color: "#FBF5B7"
      },
      potColor: {
        color: "#EC6EAD"
    },
    }
});


function Mine() {
    const classes = useStyles();
    const { activateBrowserWallet, account, deactivate } = useEthers();
    const [refLink, setRefLink] = useState("");
    if ((account !== undefined) && (refLink === "")){
        setRefLink(`https://mim-machine.com/miner?ref=${account}`);
    }
    
    return (
        <div className={ classes.container }>
            <Typography variant="h5" align="center" gutterBottom>
                <strong>MINE STARS. EARN STABLECOINS (MIM). PLAY YOUR STRATEGY.</strong> 
            </Typography>
            <Typography variant="h5" align="center" gutterBottom>
                <strong>LAUNCHING MAY 13 ON AVAX.</strong> 
            </Typography>
            <Grid container direction="row" justifyContent="start" alignItems="center">
              <Grid container item justifyContent="center">
              <Card className={classes.card} variant="outlined">
                    <CardContent>
                        <Grid container spacing={1} direction="row" justifyContent="space-between">
                            <Typography variant='h5' component='h6'>
                                <strong>CONTRACT</strong> 
                            </Typography>
                            
                            <Typography variant='h6' component='h6'>
                                {"0"} MIM
                            </Typography>
                        </Grid>
                        
                        <Box mt={2}></Box>

                        <Grid container spacing={1} direction="row" justifyContent="space-between">
                            <Typography variant='h5' component='h6'>
                                <strong>WALLET</strong> 
                            </Typography>
                            <Typography variant='h6' component='h6'>
                                {"0"} MIM
                            </Typography>
                        </Grid>

                        <Box mt={2}></Box>

                        <Grid container spacing={1} direction="row" justifyContent="space-between">
                            <Typography variant='h5' component='h6'>
                                <strong>STARS</strong> 
                            </Typography>
                            <Typography variant='h6' component='h6'>
                                {"0"} STARS
                            </Typography>
                        </Grid>
                        
                        <Box mt={2}></Box>

                        <Grid container justifyContent="center">
                                
                                <TextField
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            input: classes.textFieldColor
                                        },
                                        endAdornment: <InputAdornment component="div" position="end"><div className={classes.textFieldColor}><strong>MIM</strong></div> </InputAdornment>,
                                    }}
                                    // label="Enter Amount"
                                    value="10"
                                    variant="outlined"
                                    fullWidth
                                    onChange={undefined}
                                />
                
                        </Grid>
                        <Box mt={2}>
                            <Button className={classes.btnColorCard} fullWidth size='large'><strong>APPROVE</strong> </Button>
                        </Box>
                        <Box mt={2}>
                            <Button className={classes.btnColorCard} fullWidth size='large'><strong>HIRE {0} MINERS</strong> </Button>
                        </Box>
                        
                        <Divider className={classes.divider}/>
                        
                        <Box mt={3}></Box>
                        
                        <Grid container spacing={1} direction="row" >
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>{0} Miners -> </strong> 
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>{0} Stars</strong> 
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box mt={2}></Box>
                        
                        <Grid container spacing={1} direction="row" >
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    Estimated Daily Yield:
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>{0} MIM</strong> 
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box mt={2}></Box>
                        
                        <Grid container spacing={1} direction="row" >
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    Star Bag Full In:
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>00:00:00</strong> 
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box mt={2}></Box>
                        
                        <Grid container spacing={1} direction="row">
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    Compound Count:
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>{0} times</strong> 
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box mt={2}></Box>
                        
                        <Grid container spacing={1} direction="row">
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    Max Compound Bonus:
                                </Typography>
                            </Grid>
                            
                            <Grid item>
                                <Typography variant='h5' component='h6'>
                                    <strong>{0}%</strong> 
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        <Box mt={2}></Box>

                        <Grid container spacing={1} direction="column" justifyContent='center'>
                            <Grid item>
                                <Button className={classes.btnColorCard} fullWidth size='large'><strong>COLLECT STARS IN: 00:00:00 -80% TAX</strong></Button>
                            </Grid>
                            
                            <Grid item>
                                <Button className={classes.btnColorCard} fullWidth size='large'><strong>RE-HIRE MINERS (+{0}% Bonus)</strong> </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid className={classes.gridMargin} container direction="row" justifyContent="start" alignItems="center">
              <Grid container item justifyContent="center">
              <Card className={classes.card} variant="outlined">
                    
                    <CardContent>
                        <Box mb={2}>
                            <Typography variant='h5' component='h6'>
                                <strong>STATISTICS</strong> 
                            </Typography>
                        </Box>

                        <Divider className={classes.dividerLessMargin}/>
                        
                        <Box mt={2}></Box>
                        
                        <Grid container direction="row" justifyContent="space-between">
                            <Typography variant='h5' component='h6'>
                                DAILY RETURN
                            </Typography>
                            <Typography variant='h6' component='h6'>
                                UP TO {"8"}%
                            </Typography>
                        </Grid>

                        <Box mt={2}></Box>

                        <Grid container direction="row" justifyContent="space-between">
                            <Typography variant='h5' component='h6'>
                                APR
                            </Typography>
                            <Typography variant='h6' component='h6'>
                                UP TO {"2920"}%
                            </Typography>
                        </Grid>

                        <Box alignItems="center" display="flex" justifyContent="space-between" mt={2}>
                            <Typography variant='h5' component='h6'>
                                MAX COMPOUND BONUS
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography variant='h6' component='h6'>
                                Normal: +25% Bonus 
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography className={classes.bronzeColor} variant='h6' component='h6'>
                                Bronze Wizard Minted: +30% Bonus
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography className={classes.silverColor} variant='h6' component='h6'>
                                Silver Wizard Minted: +32.5% Bonus 
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography className={classes.goldColor} variant='h6' component='h6'>
                                Gold Wizard Minted: +35% Bonus 
                            </Typography>
                        </Box>

                        <Box alignItems="center" display="flex" justifyContent="start" mt={4} mb={2} ml={2}>
                            <Typography variant='h6' component='h6'>
                                <strong>OR MINIMUM BALANCE OF:</strong>
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography className={classes.potColor} variant='h6' component='h6'>
                                500 POTION: +30% Bonus 
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography className={classes.potColor} variant='h6' component='h6'>
                                1000 POTION: +32.5% Bonus 
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" justifyContent="start" mt={1} ml={2}>
                            <Typography className={classes.potColor} variant='h6' component='h6'>
                                1500 POTION: +35% Bonus 
                            </Typography>
                        </Box>
                        
                    </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid className={classes.gridMargin} container direction="row" justifyContent="start" alignItems="center">
              <Grid container item justifyContent="center">
              <Card className={classes.card} variant="outlined">
                    
                    <CardContent>
                        <Box mb={2}>
                            <Typography variant='h5' component='h6' align='center'>
                                <strong>Referral Link</strong> 
                            </Typography>
                        </Box>
                        
                        <Box mt={2} justifyContent="center" display="flex">
                                
                                <TextField
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                            input: classes.textFieldColorRef
                                        },
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    value={refLink}
                                />

                                <Button className={classes.btnColorCard} onClick={() =>  navigator.clipboard.writeText(`${refLink}`)}>Copy</Button>
                
                        </Box>

                        <Box mt={2}>
                            <Typography variant='body1' component='h6' align='center'>
                                Earn 8% from anyone who uses your referral link
                            </Typography>
                        </Box>
                        
                    </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="start" alignItems="center">
              <Grid container item justifyContent="center">
                <Card className={classes.card} elevation={0}>
                    <CardContent>
                        <Grid container spacing={1} direction="row">
                            <Grid container item>
                                <Button  target="_blank" href="https://discord.gg/3NjFxa4Ctt" fullWidth className={classes.btnColorCard} size='large'><strong>DISCORD</strong> </Button>
                            </Grid>
                            <Grid container item>
                                <Button target="_blank" href="/" className={classes.btnColorCard} fullWidth size='large'><strong>MIM MACHINE</strong> </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
              </Grid>
            </Grid>
        </div>    
    );
  }
  
export default Mine;